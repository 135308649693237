import { Col,Button } from "antd";
import styled from "styled-components";

export const Wrap = styled.div`
  padding-top: 2.8rem;
  padding-bottom: 3.25rem;
  padding-inline: 5rem;
  min-height: 80vh;
  @media only screen and (max-width: 1280px) {
    padding-inline: 10px;
  }
  .backHome{
    .ant-breadcrumb-link{
      .breadcrumb-point{
        display: none;
      }
      a{
        display: flex;
        align-items: center;
        span{
          font-size: 18px;
          margin-right: 10px;
        }
      }
    }
  }
  .ant-tabs .ant-tabs-ink-bar{
    background: #7d0006;
  }
  .ant-tabs-extra-content {
    min-width: 209px;
    background-color: #dcefff;
    height: 100%;
    border: 1px solid #c9c9c9;
    padding: 12px 40px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    .checkbox_checkall{
      display: flex;
      justify-content: start;
      .list-course-checked{
        appearance: none;
        background-color: rgb(255, 255, 255);
        display: inline-grid;
        place-content: center;
        width: 28px;
        height: 28px;
        border: 2px solid rgb(201, 201, 201);
        border-radius: 5px;
      }
      input[type="checkbox"] {
      appearance: none;
      background-color: #fff;
      border-radius: 2px;
      display: inline-grid;
      place-content: center;
      width: 28px;
      height: 28px;
      border: 2px solid #c9c9c9;
      border-radius: 5px;
    }

    input[type="checkbox"]::before {
      content: "";
      width: 20px;
      height: 20px;
      transform: scale(0);
      transform-origin: bottom left;
      background-color: #000;
      clip-path: polygon(39% 82%, 90% 15%, 97% 21%, 40% 96%, 0 65%, 6% 57%);
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }
    input[type="checkbox"]:checked {
      background-color: #fff;
      border: 2px solid #ccc;
    }
    span{
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 30px;
      margin-left: 14px;
    }
    }
    @media only screen and (max-width: 576px) {
      min-width: 141px !important;
      padding: 12px 10px;
    }
  }
  .ant-tabs-content-holder .ant-list.ant-list-split{
    padding-top: 0;
  }
  .ant-tabs-nav {
    margin: 0;
  }

  .ant-form-item {
    height: max-content;
    width: 100%;
    margin-bottom: 10px;

    .ant-select {
      min-height: 40px !important; 
      border-radius: 5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      .ant-select-selector{
        min-height: 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000 !important;

        .ant-select-selection-search-input {
          height: 100%;
        }

        .ant-select-selection-item{
          display: flex;
          align-items: center;
        }
      }
    }

    .ant-form-item-row{
      display: flex;
      flex-direction: column;
    }

    .ant-form-item-label{
      text-align: start;
    }

    .ant-select-disabled{
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000 !important;
    }

    .select__menu {
      z-index: 10;
    }

    .select__control {
      border: ${({ validateStatus, theme }) =>
        validateStatus === 'error'
          ? `1px solid ${theme.error_ant} !important`
          : ''};
      box-shadow: ${({ validateStatus }) =>
        validateStatus === 'error' ? 'none' : ''};
    }

    .ant-form-item-children-icon {
      display: none;
    }

    .select__dropdown-indicator {
      color: hsl(0, 0%, 60%);
    }

    label::after{
      content: "" !important;
    }
  }

  .no_information {
    font-weight: 600;
    font-size: 18px;
    color: #7D0006;
    @media only screen and (max-width: 1280px) {
      font-size: 16px;
    }
  }

  .ant-tabs-nav-list {

    .ant-tabs-tab  {
      padding: 12px 14px;
      margin:0;
      display: flex;
      align-items:center;
      border: 1px solid #7D0006;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      margin-left: 2px;
      :nth-child(2){
        margin-left: 10px;
      }
      &:hover {
        .ant-tabs-tab-btn, .icon-question {
          color: #3f8ff5 ; 
        }
      }
    }

    .icon-question {
      margin-left: 10px;
      color: #7D0006;
    }
    .ant-tabs-tab-active {
      background-color: #7D0006;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      .ant-tabs-tab-btn {
        color: #fff;
      }
      .icon-question {
       color: #fff;
      }
    }
  }
  #dropdown-year{
    .ant-picker-content{
      height:200px;
      tr{
        td:before{
          top: 40%;
              height: 35px;
        }
      }
    }
  }
`;
export const WrapperLabel = styled.div`
	width: 100%;
	font-weight: 500;
	font-size: 1.3rem;
	line-height: 30px;
	color: #6D737A;
`;

export const WearInfomation = styled.div`
  display: flex;
  .ant-form-item:nth-child(2) {
    margin: 0 10px;
  }
`

export const TitleInfo = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 30px;
  color: #6D737A;
`

export const DateTimeTitle = styled.div`
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 30px;
  color: #6d737a;
`;

export const ColWraper = styled(Col)`
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 576px) {
    justify-content: flex-start;
    button {
      margin-top: 1rem;
      width: 45px;
    }
  }
`;

export const RowListWraper = styled.div`
  width: 100%;
`;

export const FailureBtn = styled(Button)`
  background: #7d0006;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #ffffff;
  font-family: "Public Sans";
  height: 45px;
  min-width: 13rem;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #7d0006;
`;

export const NormalButton = styled.button`
  background: #e7e3e8;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #000000;
  font-family: "Public Sans";
  height: 45px;
  min-width: 13rem;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #e7e3e8;
`;

export const SuccessBtn = styled.button`
  background: #f0a7ab;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #7d0006;
  font-family: "Public Sans";
  height: 45px;
  min-width: 13rem;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #f0a7ab;
`;

export const MidleDateTime = styled(Col)`
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
`;

export const WrapperSpin = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
`

export const ButtonNewCourse = styled.div`
  padding: 4px 16px;
  background: rgb(125, 0, 6);
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin: 8px 0;
  cursor: pointer;
`